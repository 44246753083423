import React, { useEffect, useState } from "react";
import CarAdd from "./car/caradd";
import Cardetails from "./car/cardetails";
import Carlist from "./car/carlist";
import Insurance from "./car/insurance";
import Carmaintenance from "./car/maintenance";
import CarInspection from "./car/carInspection";
import CarOilCare from "./car/caroilcare";
import Carsparkplug from "./car/carsparkplug";
import Cargasolinefilter from "./car/cargasolinefilter";
import Carantifreeze from "./car/carantifreeze";
import Carbrakefluid from "./car/carbrakefluid";

import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
const radios = [
  { name: "Araç Ekle", value: 1 },
  { name: "Araç Listesi", value: 2 },
  { name: "Sigorta", value: 3 },
  { name: "Kasko", value: 4 },
  { name: "Muayene", value: 5 },

  { name: "Bakım", value: 6 },
  { name: "Yağ Değişim", value: 7 },
  { name: "Buji Değişim", value: 8 },
  { name: "Benzin Filtresi", value: 9 },
  { name: "Antfirz Değişim", value: 10 },
  { name: "Fren Hidroloği", value: 11 },

];
const radios2 = [
  { name: "Araç", value: 1,link:"/arac" },
  { name: "İş Makinası", value: 2 ,link:"/makine"},
  { name: "Ev", value: 3,link:"/ev" },
  { name: "Çıkış Yap", value: 4,link:"/" },


];
const {
  REACT_APP_SERVER_USERNAME,
  REACT_APP_SERVER_URL_API,
  REACT_APP_SERVER_PASSWORD,
  REACT_APP_JWT_NAME,
  REACT_APP_JWT_PASS,
} = process.env;
const Car = () => {
  const [caradd, setCarAdd] = useState(true);
  const [cardetails, setcardetails] = useState(false);
  const [carlist, setCarList] = useState(false);
  const [insurance, setinsurance] = useState(false);
  const [carmaintenance, setCarmaintenance] = useState(false);
  const [carinspection, setcarinspection] = useState(false);
  const [caroilcare, setcaroilcare] = useState(false);
  const [carsparkplug, setcarsparkplug] = useState(false);
  const [cargasolinefilter, setgasolinefilter] = useState(false);
  const [carantifreeze, setantifreeze] = useState(false);
  const [carbrakefluid, setbrakefluid] = useState(false);

  const [data, setresData] = useState([]);

  useEffect(() => {
    var token = localStorage.tokenWebs;
    var decoded = jwt_decode(token);
    if (
      decoded.name == REACT_APP_JWT_NAME &&
      decoded.password == REACT_APP_JWT_PASS
    ) {
      axios
        .get(`${REACT_APP_SERVER_URL_API}/car/car-get`, {
          auth: {
            username: REACT_APP_SERVER_USERNAME,
            password: REACT_APP_SERVER_PASSWORD,
          },
        })
        .then((res) => {
          setresData(res.data.message);
          toast.success(res.data.response, { autoClose: 3500 });
          //   setTimeout(() => {
          //     window.location.reload();
          //   }, 3500);
        })
        .catch((err) => {
          if (err.request.response == "") {
            toast.error("Sunucu ile bağlantı kurulamadı.");
          } else {
            toast.error(err.response.data.message);
          }
        });
    } else {
      window.location.href = "/";
    }
  }, []);

  const onClick = (e) => {
    e.preventDefault();

    if (e.target.id == "Sigorta") {
      setCarAdd(false);
      setcardetails(false);
      setCarList(false);
      setinsurance(true);
      setCarmaintenance(false);
      setcarinspection(false);
      setcaroilcare(false);
      setcarsparkplug(false);
      setgasolinefilter(false);
      setantifreeze(false);
      setbrakefluid(false);
    } else if (e.target.id == "Kasko") {
      setCarAdd(false);
      setcardetails(false);
      setCarList(true);
      setinsurance(false);
      setCarmaintenance(false);
      setcarinspection(false);
      setcaroilcare(false);
      setcarsparkplug(false);
      setgasolinefilter(false);
      setantifreeze(false);
      setbrakefluid(false);

    } else if (e.target.id == "Bakım") {
      setCarAdd(false);
      setcardetails(false);
      setCarList(false);
      setinsurance(false);
      setCarmaintenance(true);
      setcarinspection(false);
      setcaroilcare(false);
      setcarsparkplug(false);
      setgasolinefilter(false);
      setantifreeze(false);
      setbrakefluid(false);


    } else if (e.target.id == "Araç Listesi") {
      setCarAdd(false);
      setcardetails(true);
      setCarList(false);
      setinsurance(false);
      setCarmaintenance(false);
      setcarinspection(false);
      setcaroilcare(false);
      setcarsparkplug(false);
      setgasolinefilter(false);
      setantifreeze(false);
      setbrakefluid(false);


    } else if (e.target.id == "Araç Ekle") {
      setCarAdd(true);
      setcardetails(false);
      setCarList(false);
      setinsurance(false);
      setCarmaintenance(false);
      setcarinspection(false);
      setcaroilcare(false);
      setcarsparkplug(false);
      setgasolinefilter(false);
      setantifreeze(false);
      setbrakefluid(false);

    }
    else if (e.target.id == "Muayene") {
      setCarAdd(false);
      setcardetails(false);
      setCarList(false);
      setinsurance(false);
      setCarmaintenance(false);
      setcarinspection(true);
      setcaroilcare(false);
      setcarsparkplug(false);
      setgasolinefilter(false);
      setantifreeze(false);
      setbrakefluid(false);

    }
    else if (e.target.id == "Yağ Değişim") {
      setCarAdd(false);
      setcardetails(false);
      setCarList(false);
      setinsurance(false);
      setCarmaintenance(false);
      setcarinspection(false);
      setcaroilcare(true);
      setcarsparkplug(false);
      setgasolinefilter(false);
      setantifreeze(false);
      setbrakefluid(false);

    }else if (e.target.id == "Buji Değişim") {
      setCarAdd(false);
      setcardetails(false);
      setCarList(false);
      setinsurance(false);
      setCarmaintenance(false);
      setcarinspection(false);
      setcaroilcare(false);
      setcarsparkplug(true);
      setgasolinefilter(false);
      setantifreeze(false);
      setbrakefluid(false);

    }else if (e.target.id == "Benzin Filtresi") {
      setCarAdd(false);
      setcardetails(false);
      setCarList(false);
      setinsurance(false);
      setCarmaintenance(false);
      setcarinspection(false);
      setcaroilcare(false);
      setcarsparkplug(false);
      setgasolinefilter(true);
      setantifreeze(false);
      setbrakefluid(false);

    }else if (e.target.id == "Antfirz Değişim") {
      setCarAdd(false);
      setcardetails(false);
      setCarList(false);
      setinsurance(false);
      setCarmaintenance(false);
      setcarinspection(false);
      setcaroilcare(false);
      setcarsparkplug(false);
      setgasolinefilter(false);
      setantifreeze(true);
      setbrakefluid(false);

    }else if (e.target.id == "Fren Hidroloği") {
      setCarAdd(false);
      setcardetails(false);
      setCarList(false);
      setinsurance(false);
      setCarmaintenance(false);
      setcarinspection(false);
      setcaroilcare(false);
      setcarsparkplug(false);
      setgasolinefilter(false);
      setantifreeze(false);
      setbrakefluid(true);

    }
  };

  return (
    <div
      className="container-fluid pt-5 "
      style={{
        backgroundImage: "url(/car.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100%",
        minHeight: "100vh",
        width: "100%",
        minWidth: "100vw",
      }}
    >
       <div className="container-fluid mb-2">
            {radios2.map((radio, idx) => (
              <Link
                key={idx}
                className="btn btn-md btn-primary text-white mx-2 my-2"
                id={radio.name}
                to={radio.link}
              >
                {radio.name}
              </Link>
            ))}</div>
      <div className="container-fluid bg-white">
        
        <div className="row ">
       
          <div className="container-fluid">
            {radios.map((radio, idx) => (
              <button
                key={idx}
                className="btn btn-md btn-danger text-white mx-2 my-2"
                id={radio.name}
                onClick={onClick}
              >
                {radio.name}
              </button>
            ))}
          
            {caradd ? <CarAdd data={data} /> : null}
            {cardetails ? <Cardetails data={data} /> : null}
            {carinspection ? <CarInspection data={data} /> : null}
            {carlist ? <Carlist data={data} /> : null}
            {insurance ? <Insurance data={data} /> : null}
            {carmaintenance ? <Carmaintenance data={data} /> : null}
            {caroilcare ? <CarOilCare data={data} /> : null}

            {carsparkplug ? <Carsparkplug data={data} /> : null}
            {cargasolinefilter ? <Cargasolinefilter data={data} /> : null}
            {carantifreeze ? <Carantifreeze data={data} /> : null}
            {carbrakefluid ? <Carbrakefluid data={data} /> : null}

          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Car;
