import React from "react";

const Insurance = ({ data }) => {
  const days_between = (date1, date2) => {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    const differenceMs = Math.abs(date2 - date1);
    if (Math.abs(date1) > Math.abs(date2)) {
      return 0;
    } else {
      // Convert back to days and return
      return Math.round(differenceMs / ONE_DAY);
    }
  };
  const colors = (data) => {
    if (data <= 7) {
      return "#dc3545";
    } else if (data >= 7 && data <= 30) {
      return "yellow";
    } else {
      return "white";
    }
  };
  return (
    <div className="container-fluid">
      <table className="table table-responsive table-hover">
        <thead>
          <td>#</td>
          <td>Ev Adı</td>
          <td>Kime Ait</td>
          <td>Konut Acenta Adı</td>
          <td>Konut Poliçe No</td>

          <td>Gelecek Konut Poliçe Tarihi</td>
        </thead>
        <tbody>
          {data ? (
            data.map((value, index) => {
              return (
                <tr
                  key={index}
                  style={{
                    background: colors(
                      days_between(
                        new Date(),
                        new Date(value.konutsıradakipolicetarih)
                      )
                    ),
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{value.nameHome}</td>
                  <td>{value.kimeait}</td>

                  <td>{value.konutacentaadi}</td>
                  <td>{value.konutpoliceno}</td>

                  <td>
                    {new Date(value.konutsıradakipolicetarih).getDate() +
                      "-" +
                      (new Date(value.konutsıradakipolicetarih).getMonth() +
                        1) +
                      "-" +
                      new Date(value.konutsıradakipolicetarih).getFullYear()}
                  </td>

                  <td>
                    {days_between(
                      new Date(),
                      new Date(value.konutsıradakipolicetarih)
                    )}{" "}
                    gün
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td rowSpan={21}>Kayıtlı Ev Bulunamadı</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default Insurance;
