import React, { useEffect, useState } from "react";

import HomeAdd from "./home/homeAdd";
import HomePolicy from "./home/homePolicy";
import Homedask from "./home/homedask";
import Homelist from "./home/homelist";


import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
const radios = [
  { name: "Ev Ekle", value: 1 },
  { name: "Ev Listesi", value: 2 },
  { name: "Dask", value: 3 },
  { name: "Konut", value: 4 },


];
const radios2 = [
  { name: "Araç", value: 1,link:"/arac" },
  { name: "İş Makinası", value: 2 ,link:"/makine"},
  { name: "Ev", value: 3,link:"/ev" },
  { name: "Çıkış Yap", value: 4,link:"/" },


];
const {
  REACT_APP_SERVER_USERNAME,
  REACT_APP_SERVER_URL_API,
  REACT_APP_SERVER_PASSWORD,
  REACT_APP_JWT_NAME,
  REACT_APP_JWT_PASS,
} = process.env;
const Car = () => {

  const [homeadd, sethomeadd] = useState(true);
  const [homedask, sethomedask] = useState(false);
  const [homepolicy, sethomepolicy] = useState(false);
  const [homelist, sethomelist] = useState(false);

  const [data, setresData] = useState([]);

  useEffect(() => {
    var token = localStorage.tokenWebs;
    var decoded = jwt_decode(token);
    if (
      decoded.name == REACT_APP_JWT_NAME &&
      decoded.password == REACT_APP_JWT_PASS
    ) {
      axios
        .get(`${REACT_APP_SERVER_URL_API}/home/home-get`, {
          auth: {
            username: REACT_APP_SERVER_USERNAME,
            password: REACT_APP_SERVER_PASSWORD,
          },
        })
        .then((res) => {
          setresData(res.data.message);
          toast.success(res.data.response, { autoClose: 3500 });
          //   setTimeout(() => {
          //     window.location.reload();
          //   }, 3500);
        })
        .catch((err) => {
          if (err.request.response == "") {
            toast.error("Sunucu ile bağlantı kurulamadı.");
          } else {
            toast.error(err.response.data.message);
          }
        });
    } else {
      window.location.href = "/";
    }
  }, []);

  const onClick = (e) => {
    e.preventDefault();

    if (e.target.id == "Konut") {
      sethomelist(false);
      sethomepolicy(true);
      sethomedask(false);
      sethomeadd(false);
     
    } else if (e.target.id == "Dask") {
      sethomelist(false);
      sethomepolicy(false);
      sethomedask(true);
      sethomeadd(false);
      

    } else if (e.target.id == "Ev Listesi") {
      sethomelist(true);
      sethomepolicy(false);
      sethomedask(false);
      sethomeadd(false);
     


    } else if (e.target.id == "Ev Ekle") {
      sethomelist(false);
      sethomepolicy(false);
      sethomedask(false);
      sethomeadd(true);
      


    }
  };

  return (
    <div
      className="container-fluid pt-5 "
      style={{
        backgroundImage: "url(/home.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100%",
        minHeight: "100vh",
        width: "100%",
        minWidth: "100vw",
      }}
    >
        <div className="container-fluid mb-2">
            {radios2.map((radio, idx) => (
              <Link
                key={idx}
                className="btn btn-md btn-primary text-white mx-2 my-2"
                id={radio.name}
                to={radio.link}
              >
                {radio.name}
              </Link>
            ))}</div>
      <div className="container-fluid bg-white">
        <div className="row ">
      
          <div className="container-fluid">
            {radios.map((radio, idx) => (
              <button
                key={idx}
                className="btn btn-md btn-danger text-white mx-2 my-2"
                id={radio.name}
                onClick={onClick}
              >
                {radio.name}
              </button>
            ))}
          
            {homelist ? <Homelist data={data} /> : null}
            {homepolicy ? <HomePolicy data={data} /> : null}
            {homedask ? <Homedask data={data} /> : null}
            {homeadd ? <HomeAdd data={data} /> : null}

          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Car;
