import React from "react";

const Insurance = ({ data }) => {
  const km_between = (guncelkm, bakimkm) => {
    var km =bakimkm - guncelkm;
    if(km<0){
      return 0;
    }else{
    return km
    }
  };

  const colors = (data) => {
    if (data <= 1000) {
      return "#BB2D3B";
    } else if (data >= 1001 && data <= 2000) {
      return "yellow";
    } else {
      return "white";
    }
  };
  return (
    <div className="container-fluid">
      <table className="table table-responsive table-hover">
        <thead>
          <td>#</td>
          <td>Plaka</td>
          <td>Marka</td>
          <td>Model</td>
          <td>Güncel Saat</td>
          <td>Bakım Saati</td>
          <td>Kalan Saat</td>
        </thead>
        <tbody>
          {data ? (
            data.map((value, index) => {
              return (
                <tr
                  style={{
                    background: colors(
                      km_between(value.calismaSaaati, value.bakimCalismaSaati)
                    ),
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{value.plaka}</td>
                  <td>{value.marka}</td>
                  <td>{value.model}</td>
                  <td>{value.calismaSaaati}</td>
                  <td>{value.bakimCalismaSaati}</td>
                  
                  <td>
                    {km_between(value.calismaSaaati, value.bakimCalismaSaati)} saat
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td rowSpan={21}>Kayıtlı Araç Bulunamadı</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default Insurance;
