import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";

const { REACT_APP_TOKEN_USER, REACT_APP_TOKEN_PASS } = process.env;

const Home = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [visible, setVisible] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    if (username === REACT_APP_TOKEN_USER && password === REACT_APP_TOKEN_PASS) {
      localStorage.setItem("tokenWebs", process.env.REACT_APP_TOKEN);
      setVisible(true);

      toast.success("Giriş Başarılı", { autoClose: 3500 });
    } else {
      toast.error("Kullanıcı adı veya şifre hatalı", { autoClose: 3500 });
    }
  };

  return (
    <div
      className="container-fluid"
      style={{
        backgroundImage: "url(/machinery.jpg)",

        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "100%",
        minHeight: "100vh",
        width: "100%",
        minWidth: "100vw",
      }}
    >
      {!visible ? (
        <div className="container mt-0">
          <div className="row d-flex justify-content-center">
            <div className="form-group col-4">
              <form onSubmit={onSubmit} className="mt-5">
                <label
                  htmlFor="exampleDropdownFormEmail2"
                  className="text-white"
                >
                  Kullanıcı Adı
                </label>
                <input
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  type="email"
                  className="form-control "
                  id="exampleDropdownFormEmail2"
                />

                <label
                  htmlFor="exampleDropdownFormPassword2 "
                  className="text-white"
                >
                  Şifre
                </label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  type="password"
                  className="form-control"
                  id="exampleDropdownFormPassword2"
                />
                <button
                  onClick={onSubmit}
                  type="submit"
                  className="btn btn-primary btn-md mt-3 ml-auto mr-auto"
                >
                  Giriş Yap
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="row p-0 m-0">
  <section class="three-column-images mt-5 pt-5">
  <div class="container " >
    <div class="row">
      <div class="col-xs-6 col-sm-4">
      <Link
              to={"/arac"}
              className="btn btn-transparent "

>
        <img src="/car.jpg"
             class="img-thumbnail img-responsive"/></Link>
         
      </div>
   
       <div class="col-xs-6 col-sm-4">
       <Link
              to={"/makine"}
              className="btn btn-transparent "
            >     <img src="/machinery2.jpg"
             class="img-thumbnail img-responsive"/></Link>
        
      </div>
   
         <div class="col-xs-6 col-sm-4">
         <Link
              to={"/ev"}
              className="btn btn-transparent "
            ><img src="/home.jpg"
             class="img-thumbnail img-responsive"/></Link> 
        
      </div>
   
      

      
    </div> 
    
    
  </div>  
  </section>
         
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Home;
