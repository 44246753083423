import './App.css';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Machinery from './pages/Machinery';
import Car from './pages/Car';
import Home from './pages/Home';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Dashboard/>}/>
        <Route exact path="/makine" element={<Machinery/>}/>
        <Route exact path="/arac" element={<Car/>}/>
        <Route exact path="/ev" element={<Home/>}/>

      </Routes>
      </Router>
  );
}

export default App;
