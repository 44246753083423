import React from "react";

const Insurance = ({ data }) => {
  const days_between = (date1, date2) => {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date2-date1);
    if(Math.abs(date1)>Math.abs(date2)){
      return 0
    }
    else{
    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
  }
  };
  const colors = (data) => {
    if (data <= 1) {
      return "#dc3545";
    } else if (data >= 2 && data <= 14) {
      return "yellow";
    } else {
      return "white";
    }
  };
  return (
    <div className="container-fluid">
      <table className="table table-responsive table-hover">
        <thead>
          <td>#</td>
          <td>Plaka</td>
          <td>Marka</td>
          <td>Model</td>
          <td>Sigorta Tarihi</td>
          <td>Gelecek Sigorta Tarihi</td>
          <td>Kalan Gün</td>
        </thead>
        <tbody>
          {data ? (
            data.map((value, index) => {
              return (
                <tr
                  key={index}
                  style={{
                    background: colors(
                      days_between(
                        new Date(),
                        new Date(value.siradakiSigortaTarihi)
                      )
                    ),
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{value.plaka}</td>
                  <td>{value.marka}</td>
                  <td>{value.model}</td>
                  <td>
                    {new Date(value.sigortaTarihi).getDate() +
                      "-" +
                      (new Date(value.sigortaTarihi).getMonth() + 1) +
                      "-" +
                      new Date(value.sigortaTarihi).getFullYear()}
                  </td>
                  <td>
                    {new Date(value.siradakiSigortaTarihi).getDate() +
                      "-" +
                      (new Date(value.siradakiSigortaTarihi).getMonth() + 1) +
                      "-" +
                      new Date(value.siradakiSigortaTarihi).getFullYear()}
                  </td>
                  <td>
                    {days_between(
                      new Date(),
                      new Date(value.siradakiSigortaTarihi)
                    )}{" "}
                    gün
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td rowSpan={21}>Kayıtlı Araç Bulunamadı</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default Insurance;
