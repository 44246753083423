import React, { useState, Fragment } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
const {
  REACT_APP_SERVER_USERNAME,
  REACT_APP_SERVER_URL_API,
  REACT_APP_SERVER_PASSWORD,
} = process.env;
const Cardetails = (props) => {
  const [data, setData] = useState(props.data);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [yil, setyil] = useState("");
  const [bulunduguYer, setbulunduguYer] = useState("");
  const [calismaSaaati, setcalismaSaaati] = useState("");
  const [bakimCalismaSaati, setbakimCalismaSaati] = useState("");
  const [sigortaTarihi, setsigortaTarihi] = useState("");
  const [siradakiSigortaTarihi, setsiradakiSigortaTarihi] = useState("");
  const [kaskoTarihi, setkaskoTarihi] = useState("");
  const [siradakiKaskoTarihi, setsiradakiKaskoTarihi] = useState("");
  const [muayeneTarihi, setmuayeneTarihi] = useState("");
  const [siradakiMuayeneTarihi, setsiradakiMuayeneTarihi] = useState("");
  const [yagBakimi, setyagBakimi] = useState("");
  const [siradakiYagBakimi, setsiradakiYagBakimi] = useState("");
  const [buji, setbuji] = useState("");
  const [siradakiBuji, setsiradakiBuji] = useState("");
  const [benzinFiltresi, setbenzinFiltresi] = useState("");
  const [siradakiBenzinfiltresi, setsiradakiBenzinfiltresi] = useState("");
  const [antifirzFiltresi, setantifirzFiltresi] = useState("");
  const [siradakiantifirzFiltresi, setsiradakiantifirzFiltresi] = useState("");
  const [frenHidroligi, setfrenHidroligi] = useState("");
  const [siradakiFrenHidroligi, setsiradakiFrenHidroligi] = useState("");
  const [diger, setdiger] = useState("");
  const [aciklama, setaciklama] = useState("");
  const [plaka, setplaka] = useState("");
  const [marka, setmarka] = useState("");
  const [model, setmodel] = useState("");
  const [state, setState] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();
    const obj = {
      bulunduguYer,
      marka,
      model,
      yil,
      plaka,
      calismaSaaati,
      bakimCalismaSaati,
      sigortaTarihi,
      siradakiSigortaTarihi,
      kaskoTarihi,
      siradakiKaskoTarihi,
      muayeneTarihi,
      siradakiMuayeneTarihi,
      yagBakimi,
      siradakiYagBakimi,
      buji,
      siradakiBuji,
      benzinFiltresi,
      siradakiBenzinfiltresi,
      antifirzFiltresi,
      siradakiantifirzFiltresi,
      frenHidroligi,
      siradakiFrenHidroligi,
      diger,
      aciklama,
    };
    axios
      .post(`${REACT_APP_SERVER_URL_API}/construction/construction-update`, obj, {
        auth: {
          username: REACT_APP_SERVER_USERNAME,
          password: REACT_APP_SERVER_PASSWORD,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          autoClose: 3500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3500);
      })
      .catch((err) => {
        if (err.request.response == "") {
          toast.error("Sunucu ile bağlantı kurulamadı.");
        } else {
          toast.error(err.response.data.message);
        }
      });
  };

  const onSubmitDelete = (e) => {
    e.preventDefault();
    confirmAlert({
      title: "İş Makinası Sil",
      message: "İş Makinasını silmek istediğinize emin misiniz?",
      buttons: [
        {
          label: "Evet",
          onClick: () => {
    const obj = {
      plaka,
    };

    axios
      .post(`${REACT_APP_SERVER_URL_API}/construction/construction-delete`, obj, {
        auth: {
          username: REACT_APP_SERVER_USERNAME,
          password: REACT_APP_SERVER_PASSWORD,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          autoClose: 3500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3500);
      })
      .catch((err) => {
        if (err.request.response == "") {
          toast.error("Sunucu ile bağlantı kurulamadı.");
        } else {
          toast.error(err.response.data.message);
        }
      });
    },
  },
  {
    label: "Hayır",
  },
],
});
  };
  const datePrint = (date) => {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    return yyyy + "-" + mm + "-" + dd;
  };
  return (
    <div className="container-fluid">
      <table className="table table-responsive table-hover">
        <thead>
          <td>#</td>
          <td>Plaka</td>
          <td>Marka</td>
          <td>Model</td>
          <td>Yıl</td>
          <td>Bulunduğu Yer</td>

          <td></td>
        </thead>
        <tbody>
          {data ? (
            data.map((value, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{value.plaka}</td>
                  <td>{value.marka}</td>
                  <td>{value.model}</td>
                  <td>{value.yil}</td>
                  <td>{value.bulunduguYer}</td>
                  <td>
                    {state[value.plaka] ? (
                      <button
                        className="btn btn-md btn-danger text-white mx-2"
                        onClick={(e) => {
                          e.preventDefault();
                          setState({ [value.plaka]: false });
                        }}
                      >
                        Kapat
                      </button>
                    ) : (
                      <button
                        className="btn btn-md btn-warning text-white mx-2"
                        onClick={(e) => {
                          e.preventDefault();
                          setState({ [value.plaka]: true });
                          setplaka(value.plaka);
                          setyil(value.yil);
                          setbulunduguYer(value.bulunduguYer);
                          setcalismaSaaati(value.calismaSaaati);
                          setbakimCalismaSaati(value.bakimCalismaSaati);

                          let dat1 = datePrint(value.sigortaTarihi);
                          setsigortaTarihi(dat1);

                          let dat2 = datePrint(value.siradakiSigortaTarihi);
                          setsiradakiSigortaTarihi(dat2);

                          let dat3 = datePrint(value.kaskoTarihi);
                          setkaskoTarihi(dat3);

                          let dat4 = datePrint(value.siradakiKaskoTarihi);
                          setsiradakiKaskoTarihi(dat4);

                          let dat5 = datePrint(value.muayeneTarihi);
                          setmuayeneTarihi(dat5);

                          let dat6 = datePrint(value.siradakiMuayeneTarihi);
                          setsiradakiMuayeneTarihi(dat6);
                          
                          setyagBakimi(value.yagBakimi)
                          setsiradakiYagBakimi(value.siradakiYagBakimi)
                          setbuji(value.buji)
                          setsiradakiBuji(value.siradakiBuji)
                          setbenzinFiltresi(value.benzinFiltresi)
                          setsiradakiBenzinfiltresi(value.siradakiBenzinfiltresi)
                          setantifirzFiltresi(value.antifirzFiltresi)
                          setsiradakiantifirzFiltresi(value.siradakiantifirzFiltresi)
                          setfrenHidroligi(value.frenHidroligi)
                          setsiradakiFrenHidroligi(value.siradakiFrenHidroligi)
                          setmarka(value.marka)
                          setmodel(value.model)


                          setdiger(value.diger);
                          setaciklama(value.aciklama);
                        }}
                      >
                        Detay
                      </button>
                    )}
                  </td>
                  {state[value.plaka] ? (
                    <Fragment>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-header">
                              <h4
                                className="title float-left"
                                style={{ textTransform: "capitalize" }}
                              >
                                Araç Detay ({value.plaka})
                              </h4>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <form onSubmit={onSubmit}>
                                  <table className="table table-striped">
                          <tbody>
                           
                            <tr>
                              <td>
                                Marka
                                <input
                                  type="text"
                                  value={marka}
                                  className="form-control"
                                  onChange={(e) => {
                                    setmarka(e.target.value);
                                  }}
                                />
                              </td>
                              <td>
                                Model{" "}
                                <input
                                  value={model}
                                  className="form-control"
                                  onChange={(e) => {
                                    setmodel(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Yıl{" "}
                                <input
                                  value={yil}
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    setyil(e.target.value);
                                  }}
                                />
                              </td>
                              <td>
                                Bulunduğu Yer{" "}
                                <input
                                  value={bulunduguYer}
                                  className="form-control"
                                  onChange={(e) => {
                                    setbulunduguYer(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Çalışma Saati{" "}
                                <input
                                  className="form-control"
                                  type={"number"}
                                  value={calismaSaaati}
                                  onChange={(e) => {
                                    setcalismaSaaati(e.target.value);
                                  }}
                                />
                              </td>
                              <td>
                                Bakım Çalışma Saati{" "}
                                <input
                                  className="form-control"
                                  type={"number"}

                                  value={bakimCalismaSaati}
                                  onChange={(e) => {
                                    setbakimCalismaSaati(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Sigorta Tarihi{" "}
                                <input
                                  type="date"
                                  value={sigortaTarihi}
                                  className="form-control"
                                  onChange={(e) => {
                                    setsigortaTarihi(e.target.value);
                                  }}
                                />
                              </td>
                              <td>
                                Sıradaki Sigorta Tarihi{" "}
                                <input
                                  type="date"
                                  value={siradakiSigortaTarihi}
                                  className="form-control"
                                  onChange={(e) => {
                                    setsiradakiSigortaTarihi(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>
                                Kasko Tarihi{" "}
                                <input
                                  type="date"
                                  value={kaskoTarihi}
                                  className="form-control"
                                  onChange={(e) => {
                                    setkaskoTarihi(e.target.value);
                                  }}
                                />
                              </td>
                              <td>
                                Sıradaki Kasko Tarihi{" "}
                                <input
                                  type="date"
                                  value={siradakiKaskoTarihi}
                                  className="form-control"
                                  onChange={(e) => {
                                    setsiradakiKaskoTarihi(e.target.value);
                                  }}
                                />
                              </td>{" "}
                            </tr>{" "}
                            <tr>
                              <td>
                                Muayene Tarihi{" "}
                                <input
                                  type="date"
                                  value={muayeneTarihi}
                                  className="form-control"
                                  onChange={(e) => {
                                    setmuayeneTarihi(e.target.value);
                                  }}
                                />
                              </td>
                              <td>
                                Sıradaki Muayene Tarihi{" "}
                                <input
                                  type="date"
                                  value={siradakiMuayeneTarihi}
                                  className="form-control"
                                  onChange={(e) => {
                                    setsiradakiMuayeneTarihi(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>
                                Yağ Bakımı{" "}
                                <input
                                  type="number"
                                  value={yagBakimi}
                                  className="form-control"
                                  onChange={(e) => {
                                    setyagBakimi(e.target.value);
                                  }}
                                />
                              </td>
                              <td>
                                Sıradaki Yağ Bakımı{" "}
                                <input
                                  type="number"
                                  value={siradakiYagBakimi}
                                  className="form-control"
                                  onChange={(e) => {
                                    setsiradakiYagBakimi(e.target.value);
                                  }}
                                />{" "}
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>
                                Buji Değişimi{" "}
                                <input
                                  type="number"
                                  value={buji}
                                  className="form-control"
                                  onChange={(e) => {
                                    setbuji(e.target.value);
                                  }}
                                />
                              </td>
                              <td>
                                {" "}
                                Sıradaki Buji Değişimi{" "}
                                <input
                                  type="number"
                                  value={siradakiBuji}
                                  className="form-control"
                                  onChange={(e) => {
                                    setsiradakiBuji(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>
                                Benzin Filtresi{" "}
                                <input
                                  type="number"
                                  value={benzinFiltresi}
                                  className="form-control"
                                  onChange={(e) => {
                                    setbenzinFiltresi(e.target.value);
                                  }}
                                />
                              </td>
                              <td>
                                {" "}
                                Sıradaki Benzin Filtresi{" "}
                                <input
                                  type="number"
                                  value={siradakiBenzinfiltresi}
                                  className="form-control"
                                  onChange={(e) => {
                                    setsiradakiBenzinfiltresi(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>
                                Antifirz Değişimi{" "}
                                <input
                                  type="number"
                                  value={antifirzFiltresi}
                                  className="form-control"
                                  onChange={(e) => {
                                    setantifirzFiltresi(e.target.value);
                                  }}
                                />
                              </td>
                              <td>
                                {" "}
                                Sıradaki Antifirz Değişimi{" "}
                                <input
                                  type="number"
                                  value={siradakiantifirzFiltresi}
                                  className="form-control"
                                  onChange={(e) => {
                                    setsiradakiantifirzFiltresi(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>
                                Fren Hidroloği{" "}
                                <input
                                  type="number"
                                  value={frenHidroligi}
                                  className="form-control"
                                  onChange={(e) => {
                                    setfrenHidroligi(e.target.value);
                                  }}
                                />
                              </td>
                              <td>
                                {" "}
                                Sıradaki Fren Hidroloği{" "}
                                <input
                                  type="number"
                                  value={siradakiFrenHidroligi}
                                  className="form-control"
                                  onChange={(e) => {
                                    setsiradakiFrenHidroligi(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>Diğer</td>{" "}
                              <td>
                                <input
                                  value={diger}
                                  className="form-control"
                                  onChange={(e) => {
                                    setdiger(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>{" "}
                            <tr>
                              <td>Açıklama</td>{" "}
                              <td>
                                {" "}
                                <textarea
                                  value={aciklama}
                                  className="form-control"
                                  rows={3}
                                  onChange={(e) => {
                                    setaciklama(e.target.value);
                                  }}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ border: "none" }}>
                                <button
                                  className="btn btn-primary my-2 ml-auto"
                                  onClick={onSubmit}
                                >
                                  Kaydet
                                </button>
                              </td>
                              <td style={{ border: "none" }}>
                                            <button
                                              className="btn btn-danger  my-2 ml-auto"
                                              onClick={onSubmitDelete}
                                              id={plaka}
                                            >
                                              Sil
                                            </button>
                                          </td>
                            </tr>
                          </tbody>
                        </table>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ) : null}
                </tr>
              );
            })
          ) : (
            <tr>
              <td rowSpan={21}>Kayıtlı Araç Bulunamadı</td>
            </tr>
          )}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
};
export default Cardetails;
