import React from "react";
import axios from "axios";


const Insurance = ({ data }) => {
  const days_between = (date1, date2) => {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    const differenceMs = Math.abs(date2-date1);
    if(Math.abs(date1)>Math.abs(date2)){
      return 0
    }
    else{
    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
  }
  };
  const colors = (data) => {

    if (data <= 7) {

      return "#dc3545";
    } else if (data >= 7 && data <= 30) {
      return "yellow";
    } else {
      return "white";
    }
  };
  return (
    <div className="container-fluid">
      <table className="table table-responsive table-hover">
        <thead>
          <td>#</td>
          <td>Plaka</td>
          <td>Marka</td>
          <td>Model</td>
          <td>Muayene Tarihi</td>
          <td>Gelecek Muayene Tarihi</td>
          <td>Kalan Gün</td>
        </thead>
        <tbody>
          {data ? (
            data.map((value, index) => {
              return (
                <tr
                  key={index}
                  style={{
                    background: colors(
                      days_between(
                        new Date(),
                        new Date(value.siradakiMuayeneTarihi)
                      )
                    ),
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{value.plaka}</td>
                  <td>{value.marka}</td>
                  <td>{value.model}</td>
                  <td>
                    {new Date(value.muayeneTarihi).getDate() +
                      "-" +
                      (new Date(value.muayeneTarihi).getMonth() + 1) +
                      "-" +
                      new Date(value.muayeneTarihi).getFullYear()}
                  </td>
                  <td>
                    {new Date(value.siradakiMuayeneTarihi).getDate() +
                      "-" +
                      (new Date(value.siradakiMuayeneTarihi).getMonth() + 1) +
                      "-" +
                      new Date(value.siradakiMuayeneTarihi).getFullYear()}
                  </td>
                  <td>
                    {days_between(
                      new Date(),
                      new Date(value.siradakiMuayeneTarihi)
                    )}{" "}
                    gün
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td rowSpan={21}>Kayıtlı Araç Bulunamadı</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default Insurance;
