import React, { useState, Fragment } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const {
  REACT_APP_SERVER_USERNAME,
  REACT_APP_SERVER_URL_API,
  REACT_APP_SERVER_PASSWORD,
} = process.env;
const CarAdd = (props) => {
  const [data, setData] = useState(props.data);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [yil, setyil] = useState("");
  const [bulunduguYer, setbulunduguYer] = useState("");
  const [guncelKm, setguncelKm] = useState("");
  const [bakimKm, setbakimKm] = useState("");
  const [sigortaTarihi, setsigortaTarihi] = useState("");
  const [siradakiSigortaTarihi, setsiradakiSigortaTarihi] = useState("");
  const [kaskoTarihi, setkaskoTarihi] = useState("");
  const [siradakiKaskoTarihi, setsiradakiKaskoTarihi] = useState("");
  const [muayeneTarihi, setmuayeneTarihi] = useState("");
  const [siradakiMuayeneTarihi, setsiradakiMuayeneTarihi] = useState("");
  const [yagBakimi, setyagBakimi] = useState("");
  const [siradakiYagBakimi, setsiradakiYagBakimi] = useState("");
  const [buji, setbuji] = useState("");
  const [siradakiBuji, setsiradakiBuji] = useState("");
  const [benzinFiltresi, setbenzinFiltresi] = useState("");
  const [siradakiBenzinfiltresi, setsiradakiBenzinfiltresi] = useState("");
  const [antifirzFiltresi, setantifirzFiltresi] = useState("");
  const [siradakiantifirzFiltresi, setsiradakiantifirzFiltresi] = useState("");
  const [frenHidroligi, setfrenHidroligi] = useState("");
  const [siradakiFrenHidroligi, setsiradakiFrenHidroligi] = useState("");
  const [diger, setdiger] = useState("");
  const [aciklama, setaciklama] = useState("");
  const [plaka, setplaka] = useState("");
  const [marka, setmarka] = useState("");
  const [model, setmodel] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    const obj = {
      tipi: "araç",
      bulunduguYer,
      marka,
      model,
      yil,
      plaka,
      guncelKm,
      bakimKm,
      sigortaTarihi,
      siradakiSigortaTarihi,
      kaskoTarihi,
      siradakiKaskoTarihi,
      muayeneTarihi,
      siradakiMuayeneTarihi,
      yagBakimi,
      siradakiYagBakimi,
      buji,
      siradakiBuji,
      benzinFiltresi,
      siradakiBenzinfiltresi,
      antifirzFiltresi,
      siradakiantifirzFiltresi,
      frenHidroligi,
      siradakiFrenHidroligi,
      diger,
      aciklama,
    };
    axios
      .post(`${REACT_APP_SERVER_URL_API}/car/car-add`, obj, {
        auth: {
          username: REACT_APP_SERVER_USERNAME,
          password: REACT_APP_SERVER_PASSWORD,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          autoClose: 3500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3500);
      })
      .catch((err) => {
        if (err.request.response == "") {
          toast.error("Sunucu ile bağlantı kurulamadı.");
        } else {
          toast.error(err.response.data.message);
        }
      });
  };

  return (
    <div className="container-fluid">
      <form onSubmit={onSubmit}>
        <table className="table table-responsive table-hover">
          <tbody>
            <tr>
              <td colSpan={2}>
                Plaka{" "}
                <input
                  value={plaka}
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setplaka(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                Marka
                <input
                  type="text"
                  value={marka}
                  className="form-control"
                  onChange={(e) => {
                    setmarka(e.target.value);
                  }}
                />
              </td>
              <td>
                Model{" "}
                <input
                  value={model}
                  className="form-control"
                  onChange={(e) => {
                    setmodel(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                Yıl{" "}
                <input
                  value={yil}
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setyil(e.target.value);
                  }}
                />
              </td>
              <td>
                Bulunduğu Yer{" "}
                <input
                  value={bulunduguYer}
                  className="form-control"
                  onChange={(e) => {
                    setbulunduguYer(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                Güncel Km{" "}
                <input
                  className="form-control"
                  type={"number"}
                  value={guncelKm}
                  onChange={(e) => {
                    setguncelKm(e.target.value);
                  }}
                />
              </td>
              <td>
                Bakım Km{" "}
                <input
                  className="form-control"
                  type={"number"}
                  value={bakimKm}
                  onChange={(e) => {
                    setbakimKm(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                Sigorta Tarihi{" "}
                <input
                  type="date"
                  value={sigortaTarihi}
                  className="form-control"
                  onChange={(e) => {
                    setsigortaTarihi(e.target.value);
                  }}
                />
              </td>
              <td>
                Sıradaki Sigorta Tarihi{" "}
                <input
                  type="date"
                  value={siradakiSigortaTarihi}
                  className="form-control"
                  onChange={(e) => {
                    setsiradakiSigortaTarihi(e.target.value);
                  }}
                />
              </td>
            </tr>{" "}
            <tr>
              <td>
                Kasko Tarihi{" "}
                <input
                  type="date"
                  value={kaskoTarihi}
                  className="form-control"
                  onChange={(e) => {
                    setkaskoTarihi(e.target.value);
                  }}
                />
              </td>
              <td>
                Sıradaki Kasko Tarihi{" "}
                <input
                  type="date"
                  value={siradakiKaskoTarihi}
                  className="form-control"
                  onChange={(e) => {
                    setsiradakiKaskoTarihi(e.target.value);
                  }}
                />
              </td>{" "}
            </tr>{" "}
            <tr>
              <td>
                Muayene Tarihi{" "}
                <input
                  type="date"
                  value={muayeneTarihi}
                  className="form-control"
                  onChange={(e) => {
                    setmuayeneTarihi(e.target.value);
                  }}
                />
              </td>
              <td>
                Sıradaki Muayene Tarihi{" "}
                <input
                  type="date"
                  value={siradakiMuayeneTarihi}
                  className="form-control"
                  onChange={(e) => {
                    setsiradakiMuayeneTarihi(e.target.value);
                  }}
                />
              </td>
            </tr>{" "}
            <tr>
              <td>
                Yağ Bakımı{" "}
                <input
                  type="number"
                  value={yagBakimi}
                  className="form-control"
                  onChange={(e) => {
                    setyagBakimi(e.target.value);
                  }}
                />
              </td>
              <td>
                Sıradaki Yağ Bakımı{" "}
                <input
                  type="number"
                  value={siradakiYagBakimi}
                  className="form-control"
                  onChange={(e) => {
                    setsiradakiYagBakimi(e.target.value);
                  }}
                />{" "}
              </td>
            </tr>{" "}
            <tr>
              <td>
                Buji Değişimi{" "}
                <input
                  type="number"
                  value={buji}
                  className="form-control"
                  onChange={(e) => {
                    setbuji(e.target.value);
                  }}
                />
              </td>
              <td>
                {" "}
                Sıradaki Buji Değişimi{" "}
                <input
                  type="number"
                  value={siradakiBuji}
                  className="form-control"
                  onChange={(e) => {
                    setsiradakiBuji(e.target.value);
                  }}
                />
              </td>
            </tr>{" "}
            <tr>
              <td>
                Benzin Filtresi{" "}
                <input
                  type="number"
                  value={benzinFiltresi}
                  className="form-control"
                  onChange={(e) => {
                    setbenzinFiltresi(e.target.value);
                  }}
                />
              </td>
              <td>
                {" "}
                Sıradaki Benzin Filtresi{" "}
                <input
                  type="number"
                  value={siradakiBenzinfiltresi}
                  className="form-control"
                  onChange={(e) => {
                    setsiradakiBenzinfiltresi(e.target.value);
                  }}
                />
              </td>
            </tr>{" "}
            <tr>
              <td>
                Antifirz Değişimi{" "}
                <input
                  type="number"
                  value={antifirzFiltresi}
                  className="form-control"
                  onChange={(e) => {
                    setantifirzFiltresi(e.target.value);
                  }}
                />
              </td>
              <td>
                {" "}
                Sıradaki Antifirz Değişimi{" "}
                <input
                  type="number"
                  value={siradakiantifirzFiltresi}
                  className="form-control"
                  onChange={(e) => {
                    setsiradakiantifirzFiltresi(e.target.value);
                  }}
                />
              </td>
            </tr>{" "}
            <tr>
              <td>
                Fren Hidroloği{" "}
                <input
                  type="number"
                  value={frenHidroligi}
                  className="form-control"
                  onChange={(e) => {
                    setfrenHidroligi(e.target.value);
                  }}
                />
              </td>
              <td>
                {" "}
                Sıradaki Fren Hidroloği{" "}
                <input
                  type="number"
                  value={siradakiFrenHidroligi}
                  className="form-control"
                  onChange={(e) => {
                    setsiradakiFrenHidroligi(e.target.value);
                  }}
                />
              </td>
            </tr>{" "}
            <tr>
              <td>Diğer</td>{" "}
              <td>
                <input
                  value={diger}
                  className="form-control"
                  onChange={(e) => {
                    setdiger(e.target.value);
                  }}
                />
              </td>
            </tr>{" "}
            <tr>
              <td>Açıklama</td>{" "}
              <td>
                {" "}
                <textarea
                  value={aciklama}
                  className="form-control"
                  rows={3}
                  onChange={(e) => {
                    setaciklama(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ border: "none" }}>
                <button
                  className="btn btn-primary my-2 ml-auto"
                  onClick={onSubmit}
                >
                  Kaydet
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>

      <ToastContainer />
    </div>
  );
};
export default CarAdd;
