import React, { useState, Fragment } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
const {
  REACT_APP_SERVER_USERNAME,
  REACT_APP_SERVER_URL_API,
  REACT_APP_SERVER_PASSWORD,
} = process.env;
const Cardetails = (props) => {
  const [data, setData] = useState(props.data);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [aciklama, setaciklama] = useState("");
  const [konutpolicetarih, setkonutpolicetarih] = useState("");
  const [daskacentaadi, setdaskacentaadi] = useState("");
  const [dasksıradakidaskpolicetarih, setdasksıradakidaskpolicetarih] =
    useState("");
  const [daskpolicetarih, setdaskpolicetarih] = useState("");
  const [kimeait, setkimeait] = useState("");
  const [adres, setadres] = useState("");
  const [konutsıradakipolicetarih, setkonutsıradakipolicetarih] = useState("");
  const [nameHome, setnameHome] = useState("");
  const [konutacentaadi, setkonutacentaadi] = useState("");
  const [konutpoliceno, setkonutpoliceno] = useState("");
  const [daskpoliceno, setdaskpoliceno] = useState("");
  const [state, setState] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();

    const obj = {
      nameHome,
      konutacentaadi,
      konutpoliceno,
      konutsıradakipolicetarih,
      adres,
      kimeait,
      daskpolicetarih,
      dasksıradakidaskpolicetarih,
      daskpoliceno,
      daskacentaadi,
      konutpolicetarih,
      aciklama,
    };
    axios
      .post(`${REACT_APP_SERVER_URL_API}/home/home-update`, obj, {
        auth: {
          username: REACT_APP_SERVER_USERNAME,
          password: REACT_APP_SERVER_PASSWORD,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          autoClose: 3500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3500);
      })
      .catch((err) => {
        if (err.request.response == "") {
          toast.error("Sunucu ile bağlantı kurulamadı.");
        } else {
          toast.error(err.response.data.message);
        }
      });
  };

  const onSubmitDelete = (e) => {
    e.preventDefault();
    confirmAlert({
      title: "Ev Sil",
      message: "Evi silmek istediğinizden emin misiniz?",
      buttons: [
        {
          label: "Evet",
          onClick: () => {
            const obj = {
              nameHome: nameHome,
            };
            axios
              .post(`${REACT_APP_SERVER_URL_API}/home/home-delete`, obj, {
                auth: {
                  username: REACT_APP_SERVER_USERNAME,
                  password: REACT_APP_SERVER_PASSWORD,
                },
              })
              .then((res) => {
                toast.success(res.data.message, {
                  autoClose: 3500,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 3500);
              })
              .catch((err) => {
                if (err.request.response == "") {
                  toast.error("Sunucu ile bağlantı kurulamadı.");
                } else {
                  toast.error(err.response.data.message);
                }
              });
          },
        },
        {
          label: "Hayır",
        },
      ],
    });
  };
  const datePrint = (date) => {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    return yyyy + "-" + mm + "-" + dd;
  };
  return (
    <div className="container-fluid">
      <table className="table table-responsive table-hover">
        <thead>
          <td>#</td>
          <td>Ev Adı</td>
          <td>Kime Ait</td>
          <td>Dask Acenta Adı</td>
          <td>Konut Acenta Adı</td>

          <td></td>
        </thead>
        <tbody>
          {data ? (
            data.map((value, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{value.nameHome}</td>
                  <td>{value.kimeait}</td>
                  <td>{value.daskacentaadi}</td>
                  <td>{value.konutacentaadi}</td>
                  <td>
                    {state[value.nameHome] ? (
                      <button
                        className="btn btn-md btn-danger text-white mx-2"
                        onClick={(e) => {
                          e.preventDefault();
                          setState({ [value.nameHome]: false });
                        }}
                      >
                        Kapat
                      </button>
                    ) : (
                      <button
                        className="btn btn-md btn-warning text-white mx-2"
                        onClick={(e) => {
                          e.preventDefault();
                          setState({ [value.nameHome]: true });
                          setnameHome(value.nameHome);
                          setkonutacentaadi(value.konutacentaadi);
                          setkonutpoliceno(value.konutpoliceno);
                          setdaskpoliceno(value.daskpoliceno);
                          setadres(value.adres);

                          let dat1 = datePrint(value.konutsıradakipolicetarih);
                          setkonutsıradakipolicetarih(dat1);

                          let dat2 = datePrint(value.daskpolicetarih);
                          setdaskpolicetarih(dat2);

                          let dat3 = datePrint(
                            value.dasksıradakidaskpolicetarih
                          );
                          setdasksıradakidaskpolicetarih(dat3);

                          let dat4 = datePrint(value.konutpolicetarih);
                          setkonutpolicetarih(dat4);

                          setkimeait(value.kimeait);
                          setdaskacentaadi(value.daskacentaadi);

                          setaciklama(value.aciklama);
                        }}
                      >
                        Detay
                      </button>
                    )}
                  </td>
                  {state[value.nameHome] ? (
                    <Fragment>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="card-header">
                              <h4
                                className="title float-left"
                                style={{ textTransform: "capitalize" }}
                              >
                                Ev Detay ({value.nameHome})
                              </h4>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <form onSubmit={onSubmit}>
                                    <table className="table table-striped">
                                      <tbody>
                                        <tr>
                                          <td>Kime Ait </td>
                                          <td>
                                            <input
                                              type="text"
                                              value={kimeait}
                                              className="form-control"
                                              onChange={(e) => {
                                                setkimeait(e.target.value);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Dask Acenta Adı
                                            <input
                                              type="text"
                                              value={daskacentaadi}
                                              className="form-control"
                                              onChange={(e) => {
                                                setdaskacentaadi(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </td>
                                          <td>
                                            Dask Poliçe No{" "}
                                            <input
                                              value={daskpoliceno}
                                              className="form-control"
                                              onChange={(e) => {
                                                setdaskpoliceno(e.target.value);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Dask Poliçe Tarih{" "}
                                            <input
                                              value={daskpolicetarih}
                                              type="date"
                                              className="form-control"
                                              onChange={(e) => {
                                                setdaskpolicetarih(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </td>
                                          <td>
                                            Dask Poliçe Sıradaki Tarih{" "}
                                            <input
                                              type={"date"}
                                              value={
                                                dasksıradakidaskpolicetarih
                                              }
                                              className="form-control"
                                              onChange={(e) => {
                                                setdasksıradakidaskpolicetarih(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Konut Acenta Adı
                                            <input
                                              type="text"
                                              value={konutacentaadi}
                                              className="form-control"
                                              onChange={(e) => {
                                                setkonutacentaadi(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </td>
                                          <td>
                                            Konut Poliçe No{" "}
                                            <input
                                              value={konutpoliceno}
                                              className="form-control"
                                              onChange={(e) => {
                                                setkonutpoliceno(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            Konut Poliçe Tarih{" "}
                                            <input
                                              className="form-control"
                                              type={"date"}
                                              value={konutpolicetarih}
                                              onChange={(e) => {
                                                setkonutpolicetarih(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </td>
                                          <td>
                                            Konut Poliçe Sıradaki Tarih{" "}
                                            <input
                                              className="form-control"
                                              type={"date"}
                                              value={konutsıradakipolicetarih}
                                              onChange={(e) => {
                                                setkonutsıradakipolicetarih(
                                                  e.target.value
                                                );
                                              }}
                                            />
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>Adres</td>{" "}
                                          <td>
                                            {" "}
                                            <textarea
                                              value={adres}
                                              className="form-control"
                                              rows={3}
                                              onChange={(e) => {
                                                setadres(e.target.value);
                                              }}
                                            />
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>Açıklama</td>{" "}
                                          <td>
                                            {" "}
                                            <textarea
                                              value={aciklama}
                                              className="form-control"
                                              rows={3}
                                              onChange={(e) => {
                                                setaciklama(e.target.value);
                                              }}
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ border: "none" }}>
                                            <button
                                              className="btn btn-primary my-2 ml-auto"
                                              onClick={onSubmit}
                                            >
                                              Kaydet
                                            </button>
                                          </td>
                                          <td style={{ border: "none" }}>
                                            <button
                                              className="btn btn-danger  my-2 ml-auto"
                                              onClick={onSubmitDelete}
                                              id={nameHome}
                                            >
                                              Sil
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ) : null}
                </tr>
              );
            })
          ) : (
            <tr>
              <td rowSpan={21}>Kayıtlı Araç Bulunamadı</td>
            </tr>
          )}
        </tbody>
      </table>
      <ToastContainer />
    </div>
  );
};
export default Cardetails;
