import React, { useState, Fragment } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const {
  REACT_APP_SERVER_USERNAME,
  REACT_APP_SERVER_URL_API,
  REACT_APP_SERVER_PASSWORD,
} = process.env;
const CarAdd = (props) => {
  const [data, setData] = useState(props.data);
  const [modalIsOpen, setmodalIsOpen] = useState(false);

  const [aciklama, setaciklama] = useState("");
  const [konutpolicetarih, setkonutpolicetarih] = useState("");
  const [daskacentaadi, setdaskacentaadi] = useState("");
  const [dasksıradakidaskpolicetarih, setdasksıradakidaskpolicetarih] =
    useState("");
  const [daskpolicetarih, setdaskpolicetarih] = useState("");
  const [kimeait, setkimeait] = useState("");
  const [adres, setadres] = useState("");
  const [konutsıradakipolicetarih, setkonutsıradakipolicetarih] =
    useState("");
  const [nameHome, setnameHome] = useState("");
  const [konutacentaadi, setkonutacentaadi] = useState("");
  const [konutpoliceno, setkonutpoliceno] = useState("");
  const [daskpoliceno, setdaskpoliceno] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    const obj = {
      nameHome,
      konutacentaadi,
      konutpoliceno,
      konutsıradakipolicetarih,
      adres,
      kimeait,
      daskpolicetarih,
      dasksıradakidaskpolicetarih,
      daskpoliceno,
      daskacentaadi,
      konutpolicetarih,
      aciklama,
    };
    axios
      .post(`${REACT_APP_SERVER_URL_API}/home/home-add`, obj, {
        auth: {
          username: REACT_APP_SERVER_USERNAME,
          password: REACT_APP_SERVER_PASSWORD,
        },
      })
      .then((res) => {
        toast.success(res.data.message, {
          autoClose: 3500,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3500);
      })
      .catch((err) => {
        if (err.request.response == "") {
          toast.error("Sunucu ile bağlantı kurulamadı.");
        } else {
          toast.error(err.response.data.message);
        }
      });
  };

  return (
    <div className="container-fluid">
      <form onSubmit={onSubmit}>
        <table className="table table-responsive table-hover">
          <tbody>
            <tr>
              <td >
                Ev Adı{" "}
                <input
                  value={nameHome}
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    setnameHome(e.target.value);
                  }}
                />
              </td>
              <td>
                Kime Ait
                <input
                  type="text"
                  value={kimeait}
                  className="form-control"
                  onChange={(e) => {
                    setkimeait(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
          
              <td>
                Dask Acenta Adı
                <input
                  type="text"
                  value={daskacentaadi}
                  className="form-control"
                  onChange={(e) => {
                    setdaskacentaadi(e.target.value);
                  }}
                />
              </td>
              <td>
                Dask Poliçe No{" "}
                <input
                  value={daskpoliceno}
                  className="form-control"
                  onChange={(e) => {
                    setdaskpoliceno(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                Dask Poliçe Tarih{" "}
                <input
                  value={daskpolicetarih}
                  type="date"
                  className="form-control"
                  onChange={(e) => {
                    setdaskpolicetarih(e.target.value);
                  }}
                />
              </td>
              <td>
                Dask Poliçe Sıradaki Tarih{" "}
                <input
                  type={"date"}
                  value={dasksıradakidaskpolicetarih}
                  className="form-control"
                  onChange={(e) => {
                    setdasksıradakidaskpolicetarih(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                Konut Acenta Adı
                <input
                  type="text"
                  value={konutacentaadi}
                  className="form-control"
                  onChange={(e) => {
                    setkonutacentaadi(e.target.value);
                  }}
                />
              </td>
              <td>
                Konut Poliçe No{" "}
                <input
                  value={konutpoliceno}
                  className="form-control"
                  onChange={(e) => {
                    setkonutpoliceno(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                Konut Poliçe Tarih{" "}
                <input
                  className="form-control"
                  type={"date"}
                  value={konutpolicetarih}
                  onChange={(e) => {
                    setkonutpolicetarih(e.target.value);
                  }}
                />
              </td>
              <td>
                Konut Poliçe Sıradaki Tarih{" "}
                <input
                  className="form-control"
                  type={"date"}
                  value={konutsıradakipolicetarih}
                  onChange={(e) => {
                    setkonutsıradakipolicetarih(e.target.value);
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>Adres</td>{" "}
              <td>
                {" "}
                <textarea
                  value={adres}
                  className="form-control"
                  rows={3}
                  onChange={(e) => {
                    setadres(e.target.value);
                  }}
                />
              </td>
            </tr>

            <tr>
              <td>Açıklama</td>{" "}
              <td>
                {" "}
                <textarea
                  value={aciklama}
                  className="form-control"
                  rows={3}
                  onChange={(e) => {
                    setaciklama(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ border: "none" }}>
                <button
                  className="btn btn-primary my-2 ml-auto"
                  onClick={onSubmit}
                >
                  Kaydet
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>

      <ToastContainer />
    </div>
  );
};
export default CarAdd;
